import styled from "styled-components";

export const CardContainer2 = styled("button")`
  width: 100%;
`;

export const RowContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: flex-start;
  color: black;
`;
export const MapWrapper = styled.div`
  width: 100%;
  height: 600px;
`;
