import * as React from "react";
import { StandardTemplate } from "../components/Templates";
import {Accordion, Headline, LocationPin, Section } from "../components/GlobalComponents";
import { RowContainer } from "../components/ProjectComponents/Gigabee/Gigabee.styles";
import GoogleMapReact from "google-map-react";
import { mapsConfig } from "../config/config";
import { MapWrapper } from "../components/ProjectComponents/Gigabee/Gigabee.styles";

// markup
const GigabeeProtectPage = () => {
  return (
    <StandardTemplate>
      <Section>
        <Headline as={"h1"} text={"Gigabee Protect+"} styleAs={"h1"} />
        <RowContainer>
        <MapWrapper>
          <GoogleMapReact
            bootstrapURLKeys={{ key: mapsConfig.apiKey }}
            defaultCenter={{
              //51.23534988932652, 6.732338839157526
              lat: 51.23534988932652,
              lng: 6.732338839157526,
            }}
            defaultZoom={10}
          >
            <LocationPin
              lat={51.23534988932652}
              lng={6.732338839157526}
            />
            <LocationPin
              lat={51.200001}
              lng={6.433333}
            />
            <LocationPin
              lat={51.233334}
              lng={6.783333}
            />
          </GoogleMapReact>
        </MapWrapper>
        <Accordion items={[
          {
            id: "1",
            header: "Gigabee Device 1",
            children:
              "Details: Battery percentage 56%" +
              "Location: Safe" +
              "Last Updated: 13 minutes"
          },
          {
            id: "2",
            header: "Gigabee Device 2",
            children:
              "Details: Battery percentage 56%" +
              "Location: Safe " +
              "Last Updated: 13 minutes "
          },
          {
            id: "3",
            header: "Gigabee Device 3",
            children:
              "Details: Battery percentage 56%" +
              "Location: Safe" +
              "Last Updated: 13 minutes"
          }
        ]
        }
        />
      </RowContainer>
      </Section>
    </StandardTemplate>
  );
};

export default GigabeeProtectPage;
